








import { Component, Vue } from "vue-property-decorator";

import blogPostAdminModel from "@/api/blog_post.admin.model";

import EntityCrud from "../../entity/EntityCrud.vue";
import PostPhotos from "@/layouts/components/blog/admin/PostPhotos.vue";

import { Media } from "@/interfaces/media";

@Component({
	components: { EntityCrud, PostPhotos },
})
export default class BlogPostMedia extends Vue {
	model: any = blogPostAdminModel;

	title = "Gerenciamento de mídias";
	tableColumns = [
		"title",
		{
			name: "Arquivos abertos",
			key: "sourceFilesUrl",
			valueTransform: (sourceFilesUrl: string) => (sourceFilesUrl ? "Arquivo anexado" : "Nenhum arquivo"),
		},
		{
			name: "Imagens",
			key: "photos",
			valueTransform: (photos: Media[]) => {
				if (photos.length) {
					return `${photos.length} ${photos.length === 1 ? "imagem" : "imagens"}`;
				}

				return photos.length ? `${photos.length} ` : "Nenhuma imagem";
			},
		},
	];
	filter = {};

	formColumns = [{ key: "title", name: "Título", readOnly: true }, "coverUrl", "sourceFilesUrl"];
	defaultEntity = {};
}

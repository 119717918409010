import { CrudModel } from "./_crud";

import blogPostCategoryAdminModel from "./blog_post_category.admin.model";
import blogPostSubcategoryAdminModel from "./blog_post_subcategory.admin.model";
import mediaAdminModel from "./media.admin.model";

import { BlogPost } from "../interfaces/blog_post";

export class BlogPostAdminModel extends CrudModel {
	constructor() {
		super(
			"admin/blog_post",
			[],
			[
				{ fieldKey: "mainCategory", model: blogPostCategoryAdminModel },
				{ fieldKey: "subcategory", model: blogPostSubcategoryAdminModel },
				{ fieldKey: "photos", model: mediaAdminModel },
			],
		);
	}

	model = new CrudModel(this.endpoint);

	async create(postDto: BlogPost): Promise<BlogPost> {
		return this.model.create(postDto, "createPost");
	}
}

export default new BlogPostAdminModel();

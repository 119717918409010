














































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { BButton, BCard, BFormGroup, BCardTitle, BFormFile } from "bootstrap-vue";

import PhotoCard from "./PhotoCard.vue";

import { errorMessageOnAddMultipleFiles, requireConfirmation, showErrorAlert } from "@/helpers";
import { uploadFile } from "@/api/_request";
import MediaAdminModel from "@/api/media.admin.model";

import { BlogPost } from "@/interfaces/blog_post";
import { Media } from "@/interfaces/media";

@Component({ components: { BCard, BFormGroup, BCardTitle, BButton, BFormFile, PhotoCard } })
export default class PostPhotos extends Vue {
	@Prop({ required: true }) blogPostEntity: BlogPost;
	@Prop({ default: true }) isCreating: boolean;

	mediaAdminModel = MediaAdminModel;

	newFiles: File[] = [];
	files: File[] = [];
	postPhotos: Media[] = [];

	uploadedFileNames: string[] = [];

	mounted() {
		this.postPhotos = [...(this.blogPostEntity.photos ?? [])];
	}

	@Watch("newFiles")
	addFiles(newFiles: File[]) {
		if (!newFiles.length) {
			return;
		}

		const filteredFiles = Array.from(newFiles).filter(file => file.type.split("/")[0] === "image");

		if (filteredFiles.length !== newFiles.length) {
			const notAdded = newFiles.length - filteredFiles.length;

			showErrorAlert(errorMessageOnAddMultipleFiles(newFiles.length, notAdded), "Formato inválido!");
		}

		this.files.push(...filteredFiles);

		// permite readicionar a mesma imagem após remove-la
		this.newFiles = [];

		if (this.isCreating) {
			this.uploadMedia();
		}
	}

	openFile(fileUrl: string) {
		window.open(fileUrl, "_blank");
	}

	removeFile(fileIndex: number, fileName: string) {
		this.files.splice(fileIndex, 1);

		if (this.isCreating) {
			this.blogPostEntity.photos?.splice(fileIndex, 1);
			this.uploadedFileNames = this.uploadedFileNames.filter(uploadedFileName => uploadedFileName !== fileName);
		}
	}

	async removeUploadedFile(photoId: string, photoIndex: number) {
		await requireConfirmation("Confirma a exclusão?", "Não será possível reverter esta operação.", "Excluir");

		this.$store.dispatch("app/showLoading");

		try {
			await this.mediaAdminModel.delete(photoId, "");
			this.postPhotos.splice(photoIndex, 1);
		} catch {
			showErrorAlert("Houve um erro ao deletar a imagem", "Ops!");
		}

		this.$store.dispatch("app/hideLoading");
	}

	getUrl(file: File) {
		return URL.createObjectURL(file);
	}

	async uploadMedia() {
		this.$store.dispatch("app/showLoading");
		const filesToUpload = this.files.filter(file => !this.uploadedFileNames.includes(file.name));

		// upload dos arquivos
		await Promise.all(
			filesToUpload.map(async (file, fileIndex) => {
				try {
					const uploadedFile = (await uploadFile(file)) as { fileName: string };

					if (this.isCreating) {
						this.blogPostEntity.photos = [
							...(this.blogPostEntity.photos ?? []),
							{ fileUrl: uploadedFile.fileName } as Media,
						];
					} else {
						await this.mediaAdminModel.create({
							fileUrl: uploadedFile.fileName,
							blogPost: { id: this.blogPostEntity.id },
						});
					}

					this.uploadedFileNames.push(file.name);
				} catch {
					if (this.isCreating) {
						this.files.splice(fileIndex, 1);
					}

					showErrorAlert(`Erro ao enviar imagem ${file.name}`);
				}
			}),
		);

		this.$store.dispatch("app/hideLoading");

		if (!this.isCreating) {
			this.clearStagedFiles();
		}
	}

	// remove as imagens selecionados upadas com sucesso
	clearStagedFiles() {
		const stagedFiles = [...this.files];
		this.files = stagedFiles.filter(({ name }) => !this.uploadedFileNames.includes(name));

		if (stagedFiles.length !== this.files.length) {
			this.fetchNewlyUploadedPhotos();
		}

		this.uploadedFileNames = [];
	}

	async fetchNewlyUploadedPhotos() {
		this.$store.dispatch("app/showLoading");

		try {
			const filter: any = [{ field: "blogPost.id", operator: "$eq", value: this.blogPostEntity.id }];

			if (this.postPhotos.length) {
				filter.push({ field: "id", operator: "$notin", value: this.postPhotos.map(photo => photo.id) });
			}

			const photos = await this.mediaAdminModel.search({ filter });

			this.postPhotos.push(...photos);
		} catch {
			showErrorAlert("Não foi possivel obter as imagens recém upadas", "Ops!");
		}

		this.$store.dispatch("app/hideLoading");
	}

	filesCount() {
		if (!this.files.length) {
			return "Nenhuma imagem selecionada";
		}

		return `${this.files.length} ${this.files.length > 1 ? "imagens selecionadas" : "imagem selecionada"}`;
	}
}

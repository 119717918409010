
















import { Component, Prop, Vue } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";

@Component({ components: { BButton } })
export default class PhotoCard extends Vue {
	@Prop({ default: "" }) imageUrl: string;

	openFile(fileUrl: string) {
		window.open(fileUrl, "_blank");
	}
}
